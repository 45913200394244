import React from 'react';

import { useState, useEffect } from 'react';

import { stylePageTheme } from '../utils/lightUtils'
import { leTheme }        from '../utils/lightUtils'

import "../../CSS/footer.css"
import discordIcon from '../../SVG/discord.svg';
import twitterIcon from '../../SVG/twitter.svg';

function Credit(props) {
  const root = document.documentElement;
  const [light, setLight] = useState(true);
  const [isBas, setIsBas] = useState(false);



    useEffect(() => {

        setTimeout(() => {
            const creditElement = document.querySelector(".credit");
            if (creditElement) {
                creditElement.style.display = "flex";
            }
        }, 500);

        setInterval(() => {
                if (document.documentElement.scrollHeight > window.innerHeight) {
                    setIsBas(false)
                } else {
                    setIsBas(true)
                }
            }, 1);

        function handleScroll() {
            const windowHeight = window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;
            const bodyHeight = document.body.clientHeight;
            const scrollPosition = window.scrollY;
        }

        window.addEventListener('scroll', handleScroll);

        tryCode();
        function tryCode() {
            try {
                if (leTheme !== undefined) {

                        if (leTheme.id === 0) {
                            document.querySelector("#dn").checked = false;
                            stylePageTheme(root, light, props.addOn);
                            setLight(false);
                        } else {
                            document.querySelector("#dn").checked = true;
                            stylePageTheme(root, !light, props.addOn);
                            setLight(true);
                        }

                } else {
                    document.querySelector("#dn").checked = true;
                }
            } catch (error) {
                console.error("Une erreur s'est produite :", error);
                setTimeout(tryCode, 10);
            }
        }




    }, []);

  return (

    <div className={`credit ${isBas ? 'bas' : ''}`}>
      <div className='gauche'>
        <p>BlueSolo © 2023</p>
        <div className="toggleWrapper">
            <input type="checkbox" className="dn" id="dn" onClick={() => {  setLight(!light); stylePageTheme(root, light, props.addOn);}} />
            <label htmlFor="dn" className="toggle">
              <span className="toggle__handler">
                <span className="crater crater--1"></span>
                <span className="crater crater--2"></span>
                <span className="crater crater--3"></span>
              </span>
              <span className="star star--1"></span>
              <span className="star star--2"></span>
              <span className="star star--3"></span>
              <span className="star star--4"></span>
              <span className="star star--5"></span>
              <span className="star star--6"></span>
            </label>
          </div>
        </div>
        <div className="plus">
          <p>About</p>
          <a href="https://discord.gg/JzV6cSBazX" target="_blank" rel="noreferrer">
            <img src={discordIcon} alt="discord" className="discord" />
          </a>
          <a href="https://twitter.com/BlueSoloScans" target="_blank" rel="noreferrer">
            <img src={twitterIcon} alt="twitter" className="twitter" />
          </a>
        </div>

        <style jsx="true">{`
          .credit {
            display: none;
            background-color: var(--color-BS5);
            height: 63px;
            font-size: 17px;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            color: var(--color-000);
          }
          .credit p{
            font-size: 17px;
            
          }
          .credit.bas {
            position: absolute;
            bottom: 0;
          }
          .plus {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 3vw;
            margin-right: 5%;
          }

          .plus img {
            width: 30px;
            height: 30px;
          }
          .plus p{
            cursor: pointer;
          }
          .plus p{
             cursor: pointer;
           }
        `}</style>
      
    </div>
  );
}

export default Credit;
